<template>
    <div>

        <div v-if="currentCourse" class="card">
            <h1><img src="../../assets/pulsating-dot.gif" width="50" v-if="currentCourse.status == 1"/> Programma: {{ currentCourse.program.name }}</h1>
            <p>Tenuto da
                <b v-if="currentCourse.teacher">{{ currentCourse.teacher.firstname }} {{ currentCourse.teacher.lastname }}</b>
                <i v-else>insegnante non presente</i>
                nell'aula <b>{{ currentCourse.classroom }}</b> <span v-if="currentCourse.capacity > 0">,
                    capacità massima {{ currentCourse.capacity }} persone</span></p>

            <div class="action-bar row">
                <div class="col-xl-12">
                    <b-button variant="link" size="sm" @click="editCourse" v-if="hasRole('owner')">
                        <b-icon icon="pencil"/>
                        Modifica
                    </b-button>
                </div>
            </div>
        </div>

        <div v-if="currentCourse && currentCourse.status" class="">
            <br>
            <attendance-course-index
                :currentCourse="currentCourse"
                :edit="false"
                v-on:refreshed-attendances="refreshedAttendances($event)" />
        </div>
        <div v-if="currentCourse && currentCourse.programId" class="">
            <br>

            <div class="card">
                <h5>Argomenti {{ currentCourse.program.name }}</h5>
                <!--
                <div class="text-right">
                    <b-button variant="link" size="sm" @click="resetCourse">
                        Resetta tutti gli argomenti <b-icon icon="square"/>
                    </b-button>
                </div>
                <hr>
                -->
                <topic-tree-view
                    v-for="topic in currentCourse.topics"
                    :topic="topic"
                    :key="topic.id"
                    :topics="topic.children"
                    :label="topic.title"
                    :depth="0"
                    :currentCourse="currentCourse"
                    :edit="false"
                />
            </div>
        </div>
        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Carico...' }}</p>
        </div>
    </div>
</template>

<script>
import DaysOfWeek from "@/helpers/DaysOfWeek";
import CourseDataService from "./CourseDataService";
import TopicDataService from "@/components/topic/TopicDataService";
import TopicTreeView from "@/components/course/TopicTreeView";
import AttendanceCourseIndex from "@/components/attendance/AttendanceCourseIndex";
import moment from 'moment'
import VueQrcode from 'vue-qrcode'

export default {
    name: "course-handle",
    components: {TopicTreeView, AttendanceCourseIndex},
    data() {
        return {
            currentCourse: null,
            message: '',
            errorMsg: '',
            days: DaysOfWeek,
            programs: [],
            classrooms: [],
            teachers: [],
            registrationLink: '',
            date: null,
            addStudentId: null,
            qrcodeZoom: false
        };
    },
    props: ["courseId"],
    methods: {
        getCourse(id) {
            CourseDataService.get(id)
                .then(response => {
                    this.currentCourse = response.data;
                    this.currentCourse.status = (this.currentCourse.status == 1);
                    if (this.currentCourse.status) {
                        this.registrationLink = this.getRegistrationLink();
                        this.$store.state.hideProgressBar = true;
                    }
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        getRegistrationLink() {
            let urlString = window.location.protocol + '//' + window.location.host + this.$router.options.base + '#/attendance-register/';
            urlString += '?courseId='+this.currentCourse.id;
            urlString +='&courseToken='+this.currentCourse.lastToken;
            return urlString;
        },
        openCourse() {
            CourseDataService.open(this.currentCourse.id, this.currentCourse)
                .then(response => {
                    this.message = '';
                    this.currentCourse = response.data.course;
                    this.registrationLink = this.getRegistrationLink();
                    this.$store.state.hideProgressBar = true;
                    this.$emit('updated-course', this.currentCourse);
                })
                .catch(e => {
                    console.log(e);
                });


        },
        closeCourse() {
            // aggiunge il courseToken ai topic attivi che non ce l'hanno già
            function setDoneAtRecursive(topics, courseToken) {
                for (let topic of topics) {
                    if(topic.status && !topic.doneAt) {
                        topic.doneAt = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
                        topic.courseToken = courseToken
                    }
                    if (!topic.status) {
                        topic.doneAt = null
                        topic.courseToken = null
                    }
                    //console.log(topic.title, topic.doneAt)
                    if (topic.children) {
                        setDoneAtRecursive(topic.children, courseToken)
                    }
                }
            }
            if (this.currentCourse.topics) {
                setDoneAtRecursive(this.currentCourse.topics, this.currentCourse.lastToken);
            }

            CourseDataService.close(this.currentCourse.id, this.currentCourse)
                .then(response => {
                    this.message = '';
                    this.currentCourse = response.data.course;
                    this.registrationLink = '';
                    this.$store.state.hideProgressBar = false;
                    this.$emit('updated-course', this.currentCourse);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        resetCourse() {
            if(!confirm('Sei sicuro di voler reimpostare tutti gli argomenti?')) {
                return;
            }
            function resetRecursive(topics) {
                for (let topic of topics) {
                    topic.status = false;
                    topic.doneAt = null;
                    topic.courseToken = null;

                    if (topic.children) {
                        resetRecursive(topic.children)
                    }
                }
            }
            if (this.currentCourse.topics) {
                resetRecursive(this.currentCourse.topics);
            }

            CourseDataService.update(this.currentCourse.id, this.currentCourse)
                .then(response => {
                    this.$emit('updated-course', this.currentCourse);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        editCourse() {
            this.$emit('edit-course');
        },

        refreshTopics(programId) {
            TopicDataService.getAll(programId)
                .then(response => {
                    this.topics = response.data.rows;

                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.$emit('closed-editor');
        },

        refreshedAttendances(e) {
            this.currentCourse.attendances = e;
        }
    },
    mounted() {
        this.message = '';
        this.date = moment(Date.now()).format("YYYY-MM-DD");
        this.getCourse(this.courseId);
    },
    beforeDestroy() {
        this.$store.state.hideProgressBar = false;
        this.$store.state.showFullLoader = true;
    }
};
</script>

<style>
.qrcode {
    padding: 40px;
    text-align: center;
    transition: all 200ms;
    background-color: #dbdbdb;
}
.qrcodeZoom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    z-index: 1500;
    background-color: rgba(0, 0, 0, .7);
}
.qrcodeZoom img {
    width: auto;
    height: 80vh;
}
.action-bar {
    margin-top: 15px;
}

@media only screen and (max-width: 600px) {
    .qrcodeZoom img {
        width: 80vw;
        height: auto;
    }
}
</style>