<template>
    <div>
        <div class="row section-header">
            <div class="col-md-3 section-title">
                <h3>Corsi</h3>
            </div>

            <div class="col-md-6 ">

            </div>
            <div class="col-md-3"></div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentCourse = null" v-if="canCreate()">
                <b-icon icon="plus"/>
                Aggiungi Corso
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card nopadding">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Nome</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId }"
                            v-for="item in courses"
                            :key="item.id"
                            @click="setActiveCourse(item)"
                        >

                            <td>

                                <b class="">
                                    {{ item.name || item.program.name }} <img src="../../assets/pulsating-dot.gif" width="25"
                                                                 v-if="item.status"/></b>
                                <hr class="compact">
                                <p class="small text-muted course-info">
                                    <span v-for="(dayHour, index) in item.daysOfWeek" :key="index">
                                        {{ days.find(_item => _item.id === dayHour.day).name }} {{ dayHour.hour }}
                                        <span v-if="index != Object.keys(item.daysOfWeek).length - 1">, </span>
                                    </span>
                                    <br>
                                    Insegnante:
                                    <b v-if="item.teacher">{{ item.teacher.firstname }} {{ item.teacher.lastname }}</b>
                                    <i v-else>non presente</i>
                                    <span v-if="item.classroom"> - Aula: <b>{{ item.classroom }}</b></span>
                                </p>
                            </td>


                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.courses.length == 0">Ancora nessun corso presente</p>

                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <course-handle
                        v-if="hasRole('teacher') && currentCourse.teacher.id == this.$store.state.user.profileId"
                        :course-id="currentCourse.id" :key="currentCourse.id"
                        v-on:updated-course="refreshCurrentCourse($event)"
                        v-on:closed-editor="closeEditor()"
                        v-on:edit-course="setEditCourse()"/>

                    <course-view  v-else-if="hasRole('owner')"
                        :course-id="currentCourse.id" :key="currentCourse.id"
                        v-on:updated-course="refreshCurrentCourse($event)"
                        v-on:closed-editor="closeEditor()"
                        v-on:edit-course="setEditCourse()"/>
                    <div v-else class="card"><br><h4>Il corso è gestito da un altro insegnante</h4></div>
                </div>
                <div v-else-if="currentId == 0">
                    <course-create
                        v-on:created-course="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>

            </div>

            <div class="editor editor-edit" :class="{ 'opened': (currentId > 0 && editCourse) }" v-if="currentId > 0">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <course-edit v-if="hasRole('owner')" :course-id="currentCourse.id"
                             :key="currentCourse.id"
                             v-on:updated-course="refreshCurrentCourse($event)"
                             v-on:deleted-course="refreshList()"
                             v-on:closed-editor="closeEditor()"/>
            </div>

        </div>

    </div>

</template>

<script>
import CourseDataService from "./CourseDataService";
import CourseEdit from "./CourseEdit";
import CourseHandle from "./CourseHandle";
import CourseCreate from "./CourseCreate";
import CourseView from "./CourseView";
import SearchBar from "../layout/SearchBar";
import DaysOfWeek from "@/helpers/DaysOfWeek";
import {store} from "@/components/store";

export default {
    name: "courses-index",
    components: {CourseEdit, CourseCreate, CourseHandle, CourseView},
    data() {
        return {
            courses: [],
            currentCourse: null,
            currentId: -1,
            days: DaysOfWeek,
            editCourse: false
        };
    },
    methods: {
        retrieveCoursees() {
            CourseDataService.getAll()
                .then(response => {
                    this.courses = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveCoursees();
            this.currentCourse = null;
            this.currentId = -1;
        },

        refreshCurrentCourse(itemCourse) {
            let self = this;
            CourseDataService.get(itemCourse.id)
                .then(response => {
                    self.courses.forEach(function (item) {
                        if (item.id == itemCourse.id) {
                            item.name = response.data.name;
                            item.program = response.data.program;
                            item.teacher = response.data.teacher;
                            item.classroom = response.data.classroom;
                            item.status = response.data.status;
                            item.daysOfWeek = response.data.daysOfWeek;
                        }
                    });

                    self.currentId = itemCourse.id;
                    self.currentCourse = response.data;

                    //this.currentCourse.email = response.data.user.email;
                    self.currentCourse.active = (self.currentCourse.active === 1);
                })
                .catch(e => {
                    console.log("eee" + e);
                });


        },

        setActiveCourse(item) {
            this.currentCourse = item;
            this.currentId = item.id;
            this.editCourse = false;
        },

        searchName(name) {
            this.currentCourse = null;
            this.currentId = -1;
            CourseDataService.findByName(name)
                .then(response => {
                    this.courses = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            if (this.editCourse == true) {
                this.editCourse = false
                return;
            }
            this.currentCourse = null;
            this.currentId = -1;
        },
        setEditCourse() {
            this.editCourse = true;
        },
        canCreate() {
            return (this.hasRole('admin') || this.hasRole('owner'));
        },

    },
    mounted() {
        this.retrieveCoursees();
    },
};
</script>

<style scoped>
hr.compact {
    margin: 2px 0 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.03);
}

.course-title {
    display: inline-block;
    border-bottom: 1px solid #353535;
    padding-right: 15px;
    margin-bottom: 4px;
}
p.course-info {
    margin: 0;
}
.editor-edit.opened {
    top: 20vh;
}
</style>

