<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="saveCourse">

                <b-form-group id="name" label="Nome del corso" label-for="name" description="">
                    <b-form-input v-model="item.name"
                                  placeholder=""
                                  :state="validateState('name')"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <div class="row">
                    <div class="col-sm-6">
                        <b-form-group id="teacherId" label="Insegnante" label-for="teacherId" description="">
                            <b-form-select v-model="item.teacherId" :state="validateState('teacherId')">
                                <option value="0">- Scegli un insegnante -</option>
                                <option v-for="teacher in teachers" v-bind:value="teacher.id" :key="teacher.id">
                                    {{ teacher.firstname }} {{ teacher.lastname }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-sm-4">
                        <label>Aula</label>
                        <vue-typeahead-bootstrap
                            v-model="item.classroom"
                            :data="classrooms"
                            :serializer="s => s.name"
                        />
                    </div>
                    <div class="col-sm-2">
                        <b-form-group id="capacity" label="Capienza" label-for="capacity" description="">
                            <b-form-input v-model="item.capacity"
                                          placeholder=""
                                          :state="validateState('capacity')"
                                          aria-describedby="capacity-live-feedback"></b-form-input>
                            <b-form-invalid-feedback
                                id="capacity-live-feedback"
                            >Questo campo è obbligatorio.</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>

                <h6>Giornate</h6>
                <div class="row" v-for="(item,index) in item.daysOfWeek" :key="index">
                    <div class="col-sm-7">
                        <b-form-group label="Giorno" label-sr-only description="">
                            <b-form-select v-model="item.day">
                                <option value="0">- Scegli un giorno -</option>
                                <option v-for="day in days" v-bind:value="day.id" :key="day.id">
                                    {{ day.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-sm-4">
                        <b-form-group label="Ora" label-sr-only description="">
                            <b-form-input v-model="item.hour" placeholder="hh:mm"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm-1">
                        <a class="btn btn-link btn-nopadding text-danger" @click="removeDay(index)"><b-icon icon="trash"/></a>
                    </div>
                </div>
                <a class="btn btn-link" @click="addDay"><b-icon icon="plus"/> Aggiungi una giornata</a>

                <hr>

                <b-form-group id="programId" label="Programma" label-for="programId" description="">
                    <b-form-select v-model="item.programId" :state="validateState('programId')" v-on:change="retrieveTopics">
                        <option value="0">- Scegli un programma -</option>
                        <option v-for="program in programs" v-bind:value="program.id" :key="program.id">
                            {{ program.name }}
                        </option>
                    </b-form-select>
                    <!--
                    <model-list-select :list="programs"
                                       v-model="item.programId"
                                       option-value="id"
                                       option-text="name"
                                       name="programId"
                                       placeholder="Scegli il programma"
                                       :isError="!validateState('programId')">
                    </model-list-select>
                    -->
                </b-form-group>

            </b-form>
            <br>

            <topic-tree-view
                v-for="topic in item.topics"
                :topic="topic"
                :key="topic.id"
                :topics="topic.children"
                :label="topic.title"
                :depth="0"
                createCourse="true"
            />



            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="saveCourse">
                        <b-icon icon="file-earmark-check"/>
                        Salva
                    </button>
                </div>
                <div class="col-sm-4 text-right">
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade>
                    <b-icon icon="hand-thumbs-up"/>
                    {{ message }}
                </b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>

        </div>

    </div>
</template>

<script>
import CourseDataService from "./CourseDataService";
import ClassroomDataService from "@/components/classroom/ClassroomDataService";
import SchoolDataService from "@/components/school/SchoolDataService";
import TeacherDataService from "../teacher/TeacherDataService";
import TopicTreeView from "@/components/course/TopicTreeView";
import DaysOfWeek from "@/helpers/DaysOfWeek";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import {maxLength, minLength, required} from 'vuelidate/lib/validators'
import {ModelListSelect} from 'vue-search-select'
import TopicDataService from "@/components/topic/TopicDataService";

const isHourType = (value) => value && value.indexOf(":")

export default {
    name: "course-create",
    components: { VueTypeaheadBootstrap, TopicTreeView /*, ModelListSelect */},
    data() {
        return {
            item: {
                id: null,
                name: null,
                daysOfWeek: [],
                programId: null,
                teacherId: null,
                classroom: null,
                capacity: null,
                topics: []
            },
            days: DaysOfWeek,
            programs: [],
            classrooms: [],
            teachers: [],
            message: '',
            errorMsg: '',
            submitted: false
        };
    },
    validations: {
        item: {
            name: {
                required
            },
            daysOfWeek: {
                required
            },
            programId: {
                required
            },
            teacherId: {
                required
            },
            capacity: {
                required
            },
        }
    },
    methods: {
        validateState(name) {
            const {$dirty, $error} = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        saveCourse() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                // parse topics and delete all topics that's not selected
                this.item.topics = this.recursiveRemove(this.item.topics);
                CourseDataService.create(this.item)
                    .then(response => {
                        this.item.id = response.data.id;
                        this.submitted = true;
                        this.message = 'La classe è stata creata con successo';
                        this.$emit('created-course');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        closeEditor() {
            this.$emit('closed-editor');
        },
        addDay() {
            this.item.daysOfWeek.push({day: 0, hour: null})
        },
        removeDay(index) {
            this.item.daysOfWeek.splice(index, 1);
        },
        retrieveTopics() {
            TopicDataService.getAll(this.item.programId)
                .then(response => {
                    this.item.topics = response.data.rows;
                    this.recursiveEnable(this.item.topics);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        recursiveEnable(topics) {
            let self = this;
            topics.forEach(function (topic) {
                if (topic.children) {
                    self.recursiveEnable(topic.children);
                }
                topic.enabled = true;
            })
        },
        recursiveRemove (topics) {
            let self = this;
            return topics.map ( item => { return {...item} }).filter ( item => {
                if ( 'children' in item ) {
                    item.children = self.recursiveRemove (item.children);
                }
                let valid = item.enabled == true;
                item.enabled = null;
                delete item.enabled;
                return valid;
            });
        }
    },
    mounted() {
        SchoolDataService.get(this.$store.state.schoolId)
            .then(response => {
                this.programs = response.data.programs;
            })
            .catch(e => {
                console.log(e);
            });

        TeacherDataService.getAll()
            .then(response => {
                this.teachers = response.data;
            })
            .catch(e => {
                console.log(e);
            });

        ClassroomDataService.getAll()
            .then(response => {
                this.classrooms = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
.btn-nopadding {
    padding-left: 0;
    padding-right: 0;
}
</style>
