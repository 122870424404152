const DaysOfWeek = [
    {id: 1, name: 'Lunedì'},
    {id: 2, name: 'Martedì'},
    {id: 3, name: 'Mercoledì'},
    {id: 4, name: 'Giovedì'},
    {id: 5, name: 'Venerdì'},
    {id: 6, name: 'Sabato'},
    {id: 7, name: 'Domenica'},
];

export default DaysOfWeek;
