<template>
    <div class="card">
        <div class="row">
            <div class="col-4">
                <h5>Allievi presenti

                </h5>
            </div>
            <div class="col-8 text-right">
                        <span class="">In classe
                            <b-badge variant="light">
                                {{ attendances.filter(a => a.isRemote == false).length }} {{ (currentCourse.capacity > 0) ? '/ ' + currentCourse.capacity : '' }}
                            </b-badge></span>
                &nbsp;
                <span class="">Online <b-badge variant="light">{{ attendances.filter(a => a.isRemote == true).length }}</b-badge></span>
            </div>
        </div>

        <table class="table table-striped table-sm">
            <thead>
            <tr>
                <th scope="col" width="40">#</th>
                <th scope="col">Nome e cognome</th>
                <th scope="col" class="text-center"></th>
                <th scope="col" class="text-center"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="attendance in attendances"
                :key="attendance.profile.id"
            >
                <td>{{ attendance.profile.id }}</td>
                <td>{{ attendance.profile.firstname }} {{ attendance.profile.lastname }}</td>
                <td class="text-center">
                    <b-button variant="link" size="sm" v-on:click="toggleAttendanceisRemote(attendance)" v-if="edit">
                        <span v-if="attendance.isRemote"><b-icon icon="cloud"/> online</span>
                        <span v-else><b-icon icon="house"/> in classe</span>
                    </b-button>
                </td>
                <td class="text-center">
                    <b-button variant="link" size="sm" v-on:click="removeStudentFromCourse(attendance)">
                        <b-icon icon="trash" color="red"/>
                    </b-button>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="row" v-if="currentCourse.status && edit">
            <div class="col-10">
                <b-form-group id="addStudentId" label-for="addStudentId" description="">
                    <model-list-select :list="students"
                                       v-model="addStudentId"
                                       option-value="id"
                                       :custom-text="fullName"
                                       name="addStudentId"
                                       placeholder="Seleziona un allievo">
                    </model-list-select>
                </b-form-group>
            </div>
            <div class="col-2">
                <button class="btn btn-primary" @click="addStudentToCourse">
                    <b-icon icon="plus"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import AttendanceDataService from "@/components/attendance/AttendanceDataService";
import {ModelListSelect} from "vue-search-select";
import StudentDataService from "@/components/student/StudentDataService";

export default {
    name: "attendance-course-index",
    components: {ModelListSelect},
    data() {
        return {
            attendances: [],
            students: [],
            message: '',
            errorMsg: '',
            interval: null,
            addStudentId: null,
        };
    },
    props: ['currentCourse','edit'],
    methods: {
        refreshAttendances() {
            AttendanceDataService.getAll(this.currentCourse.id, this.currentCourse.lastToken)
                .then(response => {
                    this.attendances = response.data;
                    this.$emit('refreshed-attendances', this.attendances);
                    if (!this.currentCourse.status) {
                        clearInterval(this.interval);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
        addStudentToCourse() {
            const data = {
                courseId: this.currentCourse.id,
                profileId: this.addStudentId,
                courseToken: this.currentCourse.lastToken,
                isRemote: false
            }
            AttendanceDataService.create(data)
                .then(response => {
                    this.addStudentId = null;
                    this.refreshAttendances()
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        removeStudentFromCourse(attendance) {
            const fullName = attendance.profile.firstname + ' ' + attendance.profile.lastname;
            if(confirm('Sei sicuro di voler cancellare la presenza di '+fullName+'?')) {
                AttendanceDataService.delete(attendance.id)
                    .then(response => {
                        this.refreshAttendances();
                    })
                    .catch(e => {
                        this.errorMsg = e;
                        console.log(e);
                    });
            }
        },
        toggleAttendanceisRemote(attendance) {
            attendance.isRemote = !attendance.isRemote;
            AttendanceDataService.update(attendance.id, attendance)
                .then(response => {
                    console.log(response);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        setInterval() {
            var self = this;
            this.interval = setInterval(function () {
                self.refreshAttendances()
            }, 2000);
        },
        fullName (item) {
            return `${item.firstname} ${item.lastname}`
        },
    },
    mounted() {
        this.message = '';
        this.refreshAttendances()
        if (this.currentCourse.status) {
            this.setInterval();
        }

        StudentDataService.getAll()
            .then(response => {
                this.students = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    },
    beforeDestroy() {
        clearInterval(this.interval)
    }
}
</script>

<style scoped>

</style>