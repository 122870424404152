<template>
    <div >

        <div v-if="currentCourse" class="card">
            <b-form @submit="updateCourse">

                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="currentCourse.name"
                                  placeholder=""></b-form-input>
                </b-form-group>

                <div class="form-group">
                    <b-form-group id="programId" label="Programma" label-for="programId" description="">
                        <b-form-select v-model="currentCourse.programId" @change="refreshTopics(currentCourse.programId)" disabled>
                            <option value="0">- Scegli un programma -</option>
                            <option v-for="program in programs" v-bind:value="program.id" :key="program.id">
                                {{ program.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <b-form-group id="teacherId" label="Insegnante" label-for="teacherId" description="">
                            <b-form-select v-model="currentCourse.teacherId">
                                <option value="0">- Scegli un insegnante -</option>
                                <option v-for="teacher in teachers" v-bind:value="teacher.id" :key="teacher.id">
                                    {{ teacher.firstname }} {{ teacher.lastname }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-sm-4">
                        <label>Aula</label>
                        <vue-typeahead-bootstrap
                            v-model="currentCourse.classroom"
                            :data="classrooms"
                            :serializer="s => s.name"
                        />
                    </div>
                    <div class="col-sm-2">
                        <b-form-group id="capacity" label="Capienza" label-for="capacity" description="">
                            <b-form-input v-model="currentCourse.capacity"
                                          placeholder=""></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <h6>Giornate</h6>
                <div class="row" v-for="(item,index) in currentCourse.daysOfWeek" :key="index">
                    <div class="col-sm-7">
                        <b-form-group label="Giorno" label-sr-only description="">
                            <b-form-select v-model="item.day">
                                <option value="0">- Scegli un giorno -</option>
                                <option v-for="day in days" v-bind:value="day.id" :key="day.id">
                                    {{ day.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-sm-4">
                        <b-form-group label="Ora" label-sr-only description="">
                            <b-form-input v-model="item.hour" placeholder="hh:mm"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm-1">
                        <a class="btn btn-link btn-nopadding text-danger" @click="removeDay(index)"><b-icon icon="trash"/></a>
                    </div>
                </div>
                <a class="btn btn-link" @click="addDay"><b-icon icon="plus"/> Aggiungi una giornata</a>

            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateCourse" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteCourse" :disabled="this.$store.state.loading"> <b-icon icon="trash"/></b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>

        </div>

        <!--
        <div v-if="currentCourse && currentCourse.programId" class="">
            <br>

            <div class="card">
                <h5>Argomenti {{ (currentCourse.programId && programs.length > 0) ? programs.find(program => program.id == currentCourse.programId).name : '' }}</h5>
                <hr>
                <div class="container">
                    <topic-tree-view
                        v-for="topic in currentCourse.topics"
                        :topic="topic"
                        :key="topic.id"
                        :topics="topic.children"
                        :label="topic.title"
                        :depth="0"
                        :current-course="currentCourse"

                    />
                </div>
            </div>
        </div>
        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Carico...' }}</p>
        </div>
        -->
    </div>
</template>

<script>
import DaysOfWeek from "@/helpers/DaysOfWeek";
import CourseDataService from "./CourseDataService";
import ClassroomDataService from "@/components/classroom/ClassroomDataService";
import SchoolDataService from "@/components/school/SchoolDataService";
import TeacherDataService from "@/components/teacher/TeacherDataService";
import TopicTreeView from "@/components/course/TopicTreeView";
import TopicDataService from "@/components/topic/TopicDataService";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

export default {
    name: "course-edit",
    components: {VueTypeaheadBootstrap},
    data() {
        return {
            currentCourse: null,
            message: '',
            errorMsg: '',
            loading: false,
            days: DaysOfWeek,
            programs: [],
            classrooms: [],
            teachers: [],
            topics: [],
        };
    },
    props: ["courseId"],
    methods: {
        getCourse(id) {
            CourseDataService.get(id)
                .then(response => {
                    this.currentCourse = response.data;
                    //this.currentCourse.email = response.data.user.email;
                    this.currentCourse.active = (this.currentCourse.active === 1);
                    this.refreshTopics(this.currentCourse.programId)
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log("eee"+e);
                });
        },
        updateCourse() {
            this.loading = true;
            this.currentCourse.capacity = this.currentCourse.capacity || 0;
            CourseDataService.update(this.currentCourse.id, this.currentCourse)
                .then(response => {
                    this.message = 'Il corso è stato aggiornato con successo';
                    this.loading = false;
                    this.$emit('updated-course', this.currentCourse);
                })
                .catch(e => {
                    this.loading = false;
                    console.log(e);
                });
        },

        refreshTopics(programId) {
            TopicDataService.getAll(programId)
                .then(response => {
                    this.topics = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        deleteCourse() {
            if(confirm(`Vuoi cancellare questo corso?`)) {
                CourseDataService.delete(this.currentCourse.id)
                    .then(response => {
                        this.$emit('deleted-course');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },
        addDay() {
            this.currentCourse.daysOfWeek.push({day: 0, hour: null})
        },
        removeDay(index) {
            this.currentCourse.daysOfWeek.splice(index, 1);
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getCourse(this.courseId);

        SchoolDataService.get(this.$store.state.schoolId)
            .then(response => {
                this.programs = response.data.programs;
            })
            .catch(e => {
                console.log(e);
            });

        TeacherDataService.getAll()
            .then(response => {
                this.teachers = response.data;
            })
            .catch(e => {
                console.log(e);
            });

        ClassroomDataService.getAll()
            .then(response => {
                this.classrooms = response.data;
            })
            .catch(e => {
                console.log(e);
            });

    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
.btn-nopadding {
    padding-left: 0;
    padding-right: 0;
}
</style>
